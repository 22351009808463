
.swagger-editor {
  // Partials
  @import './_editor.less';
  @import './_read-only-watermark.less';
  
  @import (less) "../../node_modules/swagger-ui/dist/swagger-ui.css";
}

.swagger-editor-standalone {
  // Standalone preset
  @import "../standalone/styles/main.less";
  @import (less) "../../node_modules/react-dd-menu/dist/react-dd-menu.css";
}
